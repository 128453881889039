<template>
<v-container>
  <navbar title="بازیابی رمزعبور"/>
<v-main class="pt-15">
      <div>
        <error/>
        <success/>
      </div>
      <v-form @submit.prevent="forget()" ref="forget_pass">
      <v-card class="mx-auto mt-9 card">
        <v-card-text>
          <v-text-field :rules="[rules.required, rules.mobile, rules.type_number]" v-model="mobile" label="شماره همراه" maxlength="11" type="tell" required/>

          <!-- <v-text-field v-model="password" label="رمز عبور" :disabled="!send_code" required/>
          <div class="text-center">
          <span class="info--text">ارسال مجدد کد</span>
          </div> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="check" depressed v-if="!send_code" :class="{'is-loading': loading}" :disabled="loading" class="button" color="primary" type="submit">
            <loading v-if="loading" class="loading"/>ارسال رمزعبور
          </v-btn>
          <v-btn v-if="send_code" :class="{'is-loading': loading}" :disabled="loading" class="button" color="primary" type="submit">
            <loading v-if="loading" class="loading"/>ورود
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-main>
  </v-container>
</template>
<style lang="scss" scoped>
.v-input--selection-controls{
  margin-top: 0;
}
.v-input{
  font-size: 14px;
}

.is-loading {
  color:transparent;
}
.loading{
  font-size: 8px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
</style>
<script>
import { alertMessage } from '@/assets/messageAlert'
import success from '@/components/alerts/success.alert'
import { forget_password } from './models/auth'
import { mapState } from 'vuex'
import navbar from '@/components/navbar/appBar'
import { toEnglishDigits } from '@/models/changeType'
export default {
  components: {
    success,
    navbar
  },
  data: () => ({
    mobile: '',
    show: true,
    rules: {
      required: value => !!value || alertMessage.error['required'],
      mobile: value => {
        const mobile = toEnglishDigits(value)
       return mobile.slice(0,2) == '09' || alertMessage.error['typePhone']},
       type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage.error['type_number']
      },
    }
  }),
  computed: {
    ...mapState({
      loading: state => state.loading,
      send_code: state => state.auth.send_code
    })
  },
  methods: {
    check () {
      this.rules.required =
       value => !!value || alertMessage.error['require']
    },
    forget () {
      const validation = this.$refs.forget_pass.validate()
      if (validation == true) {
      forget_password(toEnglishDigits(this.mobile))
      }
    }
  }
}
</script>